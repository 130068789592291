@import 'partials/variable';
@import 'partials/mixin';
@import 'partials/carousel';
@import 'partials/aside';
@import 'partials/tooltip';
@import 'partials/pages';

#page_content {
    width: 100%;
    height: 100%;
    background-color: $secondary1;
    display: flex;
    flex-direction: row;
    transition: all 0.5s ease;
    h1 {
        text-transform: uppercase;
        color: white;
        text-align: center;
        padding: 3%;
    }
    #db_left {
        background-color: $secondary1;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .db_user {
            width: 100%;
            text-align: center;
        }
        .db_menu {
            width: 100%;
            nav {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                flex-wrap: wrap;
                width: 95%;
                margin: 0 auto;
                &:hover .lb_link {
                    opacity: 0.5;
                }
                .lb_link {
                    color: white;
                    background: #1e1e24;
                    padding: 0 3%;
                    margin: 0;
                    width: 13%;
                    font-weight: 300;
                    text-align: center;
                    transition: all 0.3s ease;
                    &:hover {
                        opacity: 1;
                    }
                    p {
                        width: 95%;
                        margin: 0 auto;
                        padding: 10% 0;
                        font-size: 1rem;
                        font-weight: 100;
                        transition: all 0.3s ease;
                    }
                    span {
                        transition: all 0.3s ease;
                    }
                }
            }
        }
        #bottom_menu {
            display: flex;
            width: 90%;
            justify-content: space-between;
            #db_info {
                display: flex;
                justify-content: flex-start;
                span {
                    width: 2rem;
                }
            }
            #logout {
                a {
                    color: white;
                    opacity: 0.1;
                    transition: all 0.3s ease;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            #layout_lang_switcher {
                display: flex;
                justify-content: space-around;
                width: 10%;
                text-transform: uppercase;
                @include responsive(desktop, large) {
                    width: 15%;
                }
                a {
                    color: white;
                    opacity: 0.1;
                    transition: all 0.3s ease;
                    &:hover {
                        opacity: 1;
                    }
                }
                .current_lang {
                    opacity: 1;
                }
            }
        }
        .diamond {
            left: 50%;
            bottom: 80%;
            position: absolute;
        }
    }
    #db_right {
        background: $gradient1;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        .item {
            padding-top: 0;
            flex-wrap: wrap;
            margin: 8% 0;
            .db_blog_post {
                width: 40%;
                background-color: rgba(255, 255, 255, 1);
                margin: 1%;
                .db_blog_img {
                    opacity: 0;
                    width: 90%;
                    padding: 0 5%;
                }
                .db_blog_img_container,
                .db_blog_info_container {
                    padding: 2%;
                }
                .db_blog_img_container {
                    border-bottom: 1px solid black;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    a {
                        opacity: 0;
                    }
                }
                .db_blog_info_container {
                    .db_blog_category {
                        text-transform: uppercase;
                        font-weight: 900;
                        color: black;
                    }
                    .db_blog_title {
                        color: black;
                        font-size: 1.3rem;
                    }
                    .db_blog_text {
                        color: black;
                        width: 60%;
                    }
                    .db_blog_category:hover,
                    .db_blog_title:hover,
                    .db_blog_text:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .owl-controls {
            bottom: 0;
        }
    }
}

#overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.93;
    display: none;
    z-index: 5;
}
