#db_right {
    #slider_container {
        .owl-carousel {
            .carousel-page {
                flex-wrap: wrap;
            }
            .owl-controls {
                .owl-dots {
                    .owl-dot.active {
                        background-color: white;
                    }
                    .owl-dot {
                        &:hover {
                            background-color: white;
                        }
                    }
                }
            }
        }
    }
}

#slider_container {
    background: $gradient1;
    width: 50vw;
    z-index: 0;
    position: relative;
    .owl-carousel {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .item {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 15%;
        }
        .img-left {
            display: flex;
            flex-direction: row;
            justify-content: center;
            img {
                @include responsive(desktop, large) {
                    width: 50%;
                }
            }
            .slider-text {
                text-align: left;
                max-width: 37%;
                padding-top: 5%;
            }
        }
        .img-top a {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            color: white;
            .slider-text {
                text-align: center;
                max-width: 75%;
                padding-top: 5%;
            }
            img {
                max-width: 45%;
                height: auto;
            }
        }
        .slider-text {
            text-transform: uppercase;
            font-size: 2rem;
            @include responsive(desktop, large) {
                font-size: 2rem;
            }
            @include responsive(tablet, landscape) {
                font-size: 1.5rem;
            }
            & heavy {
                font-size: 2rem;
            }
        }
        .owl-controls {
            position: absolute;
            bottom: 5%;
            width: 100%;
            .owl-dots {
                display: flex;
                justify-content: center;
                margin: 2% 0;
                .owl-dot {
                    background-color: $primary0;
                    border-radius: 20px;
                    display: block;
                    height: 12px;
                    width: 12px;
                    margin: 7px 7px;
                    transition: background-color 0.2s ease;
                    &:hover {
                        background-color: white;
                    }
                }
                .active {
                    background-color: white;
                }
            }
        }
    }
    @include responsive(tablet, portrait) {
        display: none;
    }
    @include responsive(phone, landscape) {
        display: none;
    }
}