aside {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary1;
    .avatar {
        width: 10vw;
        height: 10vw;
        border-width: 5px;
        border-style: solid;
        border-radius: 100%;
        margin: 3% auto;
        background-position: center;
        background-size: cover;
        p {
            margin-top: 40%;
            font-size: 2rem;
            text-align: center;
            font-weight: 500;
            color: white;
        }
    }
    .greeting,
    .greeting span {
        font-size: 1.5rem;
        text-align: center;
        position: relative;
        z-index: 3;
    }
    .greeting_time {
        font-weight: 200;
        span {
            display: none;
        }
    }
    .greeting_name {
        font-weight: 600;
    }
    .explanation {
        color: white;
        padding: 10%;
        font-size: 1rem;
        min-height: 50%;
        p {
            line-height: 1.5rem;
            margin-bottom: 5%;
            color: lightgray;
            text-align: left;
            font-weight: 300;
        }
    }
    .info {
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.2s ease;
        &:hover {
            opacity: 1;
        }
        p {
            padding: 15%;
            margin: auto;
        }
    }
    .diamond {
        position: relative;
        z-index: 1;
        left: 55%;
        bottom: 65%;
        width: 40px;
        height: 40px;
        background: $secondary1;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
    }
}
