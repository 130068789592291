.lb_container {
    position: absolute;
    top: 2vh;
    left: 1vw;
    width: 98vw;
    height: 96vh;
    z-index: 10;
    pointer-events: none;
    .lb_section {
        width: 0%;
        height: 0%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        overflow: hidden;
        background-color: $secondary1;
        pointer-events: all;
        .lb_left_side {
            width: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $secondary1;
            @include responsive('desktop', 'large') {
                width: 25%;
            }
            .explanation {
                ul {
                    li {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        text-align: left;
                        padding: 1% 0;
                        span {
                            width: 2rem;
                        }
                        p {
                            margin: 0 0 0 5%;
                        }
                    }
                }
            }
        }
        .lb_right_side {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background: $gradient1;
            .lb_title {
                font-size: 3rem;
                color: white;
                font-weight: 900;
                margin-bottom: 3%;
                text-transform: uppercase;
                width: 100%;
                text-align: center;
                span {
                    display: inline-block;
                    width: 2%;
                    padding: 1%;
                }
            }
            .alert {
                display: none;
                width: 50%;
                padding: 2%;
                border-left: 1px solid;
                border-right: 1px solid;
                border-top: 2px solid;
                span {
                    display: none;
                }
                a {
                    color: inherit;
                }
                p {
                    font-weight: 400;
                    text-align: center;
                    text-transform: uppercase;
                    margin: auto 0;
                }
                & .warning_wrapper {
                    .thumb-up,
                    .thumb-down {
                        display: block;
                        cursor: pointer;
                        text-transform: uppercase;
                        text-align: center;
                        font-weight: 900;
                        width: 100%;
                        margin: 0 auto;
                        transition: all 0.3s ease;
                        &:before {
                            font-size: 3rem;
                            display: block;
                        }
                    }
                    .thumb-up {
                        &:hover + p {
                            color: $success;
                            font-weight: 900;
                        }
                    }
                    .thumb-down {
                        &:hover + p {
                            color: $error;
                            font-weight: 900;
                        }
                    }
                }
            }
            hr {
                border-width: 1px;
                border-style: solid;
                width: 80%;
            }
            .modif_container {
                width: 80%;
                overflow-y: auto;
                &::-webkit-scrollbar-track {
                    background-color: white;
                    border-left: solid 1px black;
                }
                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #34353e;
                }
                form {
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .choice {
                        display: flex;
                        justify-content: space-around;
                        width: 30%;
                        padding: 1%;
                        input[type=radio] {
                            position: absolute;
                            visibility: hidden;
                            z-index: -1;
                            transition: all 0.3s ease;
                            &:checked + .check {
                                border-width: 5px;
                                border-style: solid;
                            }
                            &:checked + .check + label img {
                                border-width: 5px;
                                border-style: solid;
                            }
                        }
                        label {
                            cursor: pointer;
                            padding: 1%;
                            transition: all 0.3s ease;
                            text-align: center;
                            img {
                                box-sizing: border-box;
                                border: 5px solid transparent;
                                width: 85%;
                            }
                        }
                        .check {
                            cursor: pointer;
                            border: 5px solid white;
                            border-radius: 100%;
                            margin: auto 0;
                            transition: all 0.3s ease;
                            &::before {
                                border-radius: 100%;
                                display: block;
                                content: '';
                                width: 1rem;
                                height: 1rem;
                            }
                        }
                    }
                    .input_file {
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;
                        color: transparent;
                        border: none;
                        &::-webkit-file-upload-button {
                            visibility: hidden;
                        }
                        & + label {
                            display: inline-block;
                            background-color: white;
                            border-width: 1px;
                            border-style: solid;
                            border-radius: 5px;
                            text-align: center;
                            padding: 2%;
                            cursor: pointer;
                            position: relative;
                            width: 100%;
                            transition: all 0.3s ease;
                            &:hover {
                                color: white;
                                span {
                                    color: white;
                                }
                            }
                        }
                        &:focus + label {
                            outline: -webkit-focus-ring-color auto 5px;
                        }
                    }
                }
            }
            .close {
                position: relative;
                margin: 1%;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                cursor: pointer;
                opacity: 0.3;
                align-self: flex-end;
                transition: all 0.5s ease;
                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: '';
                    height: 32px;
                    width: 2px;
                    transition: all 0.5s ease;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(135deg);
                }
                &:hover {
                    opacity: 1;
                    transform: rotate(360deg);
                }
            }
            .count {
                position: relative;
                left: 46%;
                top: 2%;
                font-size: 2rem;
                transition: all 0.2s ease;
            }
            .submit_button_wrapper {
                position: absolute;
                bottom: 5%;
                right: 1%;
                div {
                    padding-right: 1%;
                    label {
                        display: none;
                    }
                }
            }
            .first_connection_nav {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                position: relative;
                top: 20%;
            }
            .finished,
            .next,
            .previous {
                overflow: auto;
                margin: 1%;
                min-height: 4%;
                border: 1px solid white;
                border-radius: 20px;
                padding: 10px 20px;
                max-width: 100%;
                opacity: 1;
                cursor: pointer;
                color: white;
                transition: all 0.2s ease;
            }
        }
    }
}
