.tooltip_wrapper {
    display: none;
    position: absolute;
    z-index: 2;
    left: 5%;
    top: 94%;
    pointer-events: none;
    max-width: 40%;
    .tooltip_container {
        border-width: 5px;
        border-style: solid;
        background-color: white;
        padding: 5%;
        width: auto;
        max-width: 100%;
        .tooltip_info {
            display: none;
            p {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}